import React, { useRef } from 'react'

import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { GlobalModalTypeEnum, MODALS_DEFAULT_STATE, ModalsPlugin } from '@api/local'
import { Button, Link, Paragraph, Spacer } from '@client/components'
import { Modal, SectionLoading } from '@client/components/molecules'
import { ModalActionContainer, ModalContainerStyle } from '@client/components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { CartItemFragment, RegisteredUserDetailsFragment, useGetModalsQuery, UserDetailsFragment, useRemoveItemFromCartMutation, UserSubscriptionFragment, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { MarketProduct, UserSubscriptionStatusEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  ${ModalContainerStyle}

  .item-container {
    justify-content: flex-start;
  }
`
const ActionContainer = styled.div`
  ${ModalActionContainer()};
`

export function NotifyMarketProductsModal(): JSX.Element {

  const config = useConfig()
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const events = useEvents()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const cartMarketProducts = userCartData?.currentUser?.activeCart?.cartItems?.filter((item) => item.product?.__typename === 'MarketProduct') || []
  const removedMarketProducts = useRef(0)
  const { addToast } = useToasts()
  const [removeItemFromCart] = useRemoveItemFromCartMutation()

  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const isActiveSubscription = subscription?.status === UserSubscriptionStatusEnum.ACTIVE
  const loading = userDetailsLoading || userCartLoading

  const _handleModalClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.NOTIFY_MARKET_PRODUCTS)
  }

  const _openPauseModal = () => {
    if (isActiveSubscription) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION, null, true)
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION)
    }
  }

  const _handleContinuePausing = async (): Promise<void> => {
    await _removeAllMarketProducts()
    _openPauseModal()
  }

  const _removeAllMarketProducts = async () => {
    await Promise.all(
      cartMarketProducts.map(item => _handleRemoveMarketProducts(item)),
    )
  }

  const _handleRemoveMarketProducts = async (item: CartItemFragment): Promise<void> => {
    const product = item.product as unknown as MarketProduct

    try {
      await removeItemFromCart({
        variables: {
          productId: product.id,
        },
      })

      const logData = {
        itemName: product?.name,
        itemId: product?.id,
        itemImage: product?.coverImage?.location,
        price: product?.price,
        itemBrand: 'UCOOK',
        itemListName: 'Market Product',
        itemChef: 'UCOOK',
        isMealkit: 'no',
        item_variant: product?.marketProductCategories?.map((cat) => cat.title).join(', '),
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)

      removedMarketProducts.current = removedMarketProducts.current + 1

      addToast(`Removed ${logData.itemName} from cart`, {
        appearance: 'warning',
        autoDismiss: true,
      })

    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  return (
    <Modal
      title='Hold up, Chef!'
      open={data?.modals?.notifyMarketProducts}
      allowBackgroundClose={false}
      contentOverflow='initial'
      modalOverflow='initial'
      fullscreen={false}
      onClose={_handleModalClose} >
      <Container>
        <Choose>
          <When condition={loading}>
            <SectionLoading />
          </When>
          <Otherwise>
            <>
              <Paragraph variant='p1' bold align='center'>Stay activated to keep shopping</Paragraph>
              <Spacer universal='16px' />
              <Paragraph variant='p2' align='center'>
                Market Items are only available with an active Meal Kit plan.
              </Paragraph>
              <Spacer universal='24px' />
              <Paragraph variant='p2' align='center'>
                Want to keep these items in your cart?
              </Paragraph>
              <Spacer universal='4px' />
              <Paragraph variant='p2' align='center'>
               Simply keep your plan active, and you are good to go!
              </Paragraph>
              <Spacer universal='16px' />
              <ActionContainer>
                <Button
                  color='black'
                  fullWidth
                  title='PAUSE PLAN'
                  onClick={_handleContinuePausing} />
                <Spacer mobile='8px' desktop='16px' />
                <Link variant='l2' decoration='underline' onClick={_handleModalClose}> Cancel </Link>
              </ActionContainer>
            </>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )
}
