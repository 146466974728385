export class Utilities {

  static utilities: Utilities | null = null
  times: { time: number, message: string }[] = []

  static shared(): Utilities {
    if (!this.utilities) {
      this.utilities = new Utilities()
    }
    return this.utilities
  }

  beginTimeLog(): void {
    this.times = []
  }

  logTime(message: string): void {
    this.times.push({ message, time: Date.now() })
  }

  endTimeLog(message?: string): void {
    if (message) {
      console.log('\n------------------------')
      console.log(`${message}`)
      console.log('------------------------\n')
    } else {
      console.log('\n------------------------')
      console.log('TIME LOG')
      console.log('------------------------\n')
    }
    for (let t = 0; t < this.times.length - 1; t++) {
      console.log(`${(t + 1)}. ${this.times[t].message}`)
      console.log(`---- ${(this.times[(t + 1)].time - this.times[t].time)} ms`)
    }
    console.log(`${(this.times.length)}. ${this.times[this.times.length - 1].message}`)
    console.log('\n------------------------')
    console.log(`TOTAL TIME: ${(this.times[this.times.length - 1].time - this.times[0].time)} ms`)
    console.log('------------------------\n')
  }

  public static validateEmail(value: string): boolean {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase())
  }

  public static toSnakeCase(obj: Record<string, any>): Record<string, any> {
    const newObj: Record<string, any> = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
        newObj[snakeCaseKey] = obj[key]
      }
    }
    return newObj
  }

  public static randomString(size: number): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let randomString = ''
    for (let x = 0; x < size; x++) {
      const charIndex = Math.floor(Math.random() * characters.length)
      randomString += characters.substring(charIndex, charIndex + 1)
    }
    return randomString
  }

  public static randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  public static randomColor(): string {
    let hex = Math.floor(Math.random() * 16777215).toString(16)
    if (hex.length < 6) {
      hex += '0'
    }
    return `#${hex}`
  }

  public static wait(duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), duration)
    })
  }

  public static getArrayDiff<T>(oldValues: T[], newValues: T[]): { add: T[], remove: T[], update: T[] } {
    const add: T[] = []
    const remove: T[] = []
    const update: T[] = []

    oldValues.forEach((val) => {
      if (newValues.includes(val)) {
        update.push(val)
      } else {
        remove.push(val)
      }
    })

    newValues.forEach((val) => {
      if (!oldValues.includes(val)) {
        add.push(val)
      }
    })

    return {
      add,
      remove,
      update,
    }
  }

  public static chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = []
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize)
      chunks.push(chunk)
    }
    return chunks
  }

  public static parseBoolean(value: string): boolean | undefined {
    if (value === 'true') return true
    if (value === 'false') return false
    return undefined
  }

  public static convertToKebabCase(input: string): string {
    if (typeof input !== 'string') {
      throw new TypeError('Input must be a string')
    }

    return input
      .trim() // Remove leading/trailing spaces
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9\s&-]+/g, '') // Allow only alphanumerics, spaces, &, and -
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-{2,}/g, '-') // Collapse consecutive hyphens
      .replace(/^-+|-+$/g, '') // Trim hyphens from start/end
  }

}
