import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Button, Link, Paragraph, Spacer } from '@client/components'
import { ModalActionContainer, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import {
  ActiveCampaignWinesDocument, RegisteredUserDetailsFragment, UserCartDocument, UserDetailsDocument, UserDetailsFragment,
  UserSubscriptionFragment, useGetAllMealKitCategoriesQuery, useGetApiEnumQuery, useUpdateUserSubscription, useUserCartQuery,
  useUserDetailsQuery,
} from '@hooks/api'
import { FieldData, Form, Modal, SectionLoading, SelectInput, SelectOption, useForm } from '@molecules/index'
import { ThursdayDelivery } from '@organisms/modals'
import {
  NumberOfPortionsEnum, CityDeliveryDaysEnum, MealKitCategoryStatusEnum, MealKitCategoryOrderEnum,
  OrderDirectionEnum, WeekDayEnum,
} from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '576px' })}
  ${ResponsivePXValue('padding', '16px 24px')}

  .dropdown {
    ${ZeroSpace}
    .input-label {
      font-weight: 400;
    }
  }
`

const TransitionDiv = styled.div`
  display: flex;
  justify-content: center;
`
const ActionContainer = styled.div<{ $stickyBottom: boolean }>`
  ${ModalActionContainer(true)};
`
interface EditSubscriptionModalFormData {
  category: string | null
  numberOfPortions: NumberOfPortionsEnum | null
  numberOfMeals: number | null
  deliveryOption: WeekDayEnum | null
}

export interface EditSubscriptionModalProps {
  open?: boolean
  onClose: () => void
}

interface EditSubscriptionModalState {
  updating: boolean
  thursdayDeliverySelected: boolean
  displayThursdayDelivery: boolean
  categoryOptions: { title: string, value: string }[]
  numberOfDefaultMealOptions: { title: string, value: number }[]
  deliveryOptions: { title: string, value: string }[]
}

const DEFAULT_STATE: EditSubscriptionModalState = {
  updating: false,
  thursdayDeliverySelected: false,
  displayThursdayDelivery: false,
  numberOfDefaultMealOptions: [
    {
      title: '2',
      value: 2,
    }, {
      title: '3',
      value: 3,
    }, {
      title: '4',
      value: 4,
    },
  ],
  categoryOptions: [],
  deliveryOptions: [],
}

export function EditSubscriptionModal({ open, onClose }: EditSubscriptionModalProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<EditSubscriptionModalState>({ ...DEFAULT_STATE })
  const { data: deliveryDayData, loading: deliveryDayEnumLoading } = useGetApiEnumQuery({ variables: { enum: 'MealKitDeliveryOptionEnum' } })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: mealKitCategoriesData, loading: mealKitCategoriesLoading } = useGetAllMealKitCategoriesQuery({
    ssr: config.fetchSSRQuery(),
    variables: {
      filters: {
        canSubscribe: true,
        status: [MealKitCategoryStatusEnum.ACTIVE],
      },
      order: {
        field: MealKitCategoryOrderEnum.DISPLAY_INDEX,
        direction: OrderDirectionEnum.ASC,
      },
    },
  })
  const [updateSubscription] = useUpdateUserSubscription()
  const [form] = useForm()
  const { addToast } = useToasts()

  const weekDaySorter: { [k: string]: number } = {
    SUNDAY: 0,
    MONDAY: 1,
    THURSDAY: 2,
  }

  useEffect(() => {
    if (!userCartLoading && !mealKitCategoriesLoading && !userDetailsLoading && !deliveryDayEnumLoading) {
      const categoryOptions: { title: string, value: string }[] = mealKitCategoriesData?.mealKitCategories?.list?.map((category) => ({ title: category.title, value: category.id })) || []
      const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
      const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)

      const formData: EditSubscriptionModalFormData = {
        category: subscription?.category?.id,
        numberOfPortions: subscription?.numberOfPortions,
        numberOfMeals: subscription?.numberOfMeals,
        deliveryOption: subscription?.deliveryOption,
      }

      const defaultAddress = userDetailsData?.currentUser?.addresses?.find((address) => address.isDefault)
      let deliveryOptions = [] as SelectOption[]
      if (defaultAddress && deliveryDayData?.enum?.values) {
        const deliveryDays = defaultAddress?.location?.city?.deliveryDays
        deliveryOptions = deliveryDayData.enum.values.map((enumVal) => ({
          title: enumVal.title,
          value: enumVal.value,
          disabled: !deliveryDays.includes(enumVal.value as CityDeliveryDaysEnum),
        })).sort((a, b) => weekDaySorter[a.value] - weekDaySorter[b.value])
      }

      form.setFieldsValue(formData)

      setState((prevState) => update(prevState, {
        categoryOptions: { $set: categoryOptions },
        deliveryOptions: { $set: deliveryOptions },
      }))
    }
  }, [userCartLoading, mealKitCategoriesLoading, userDetailsLoading, deliveryDayEnumLoading])

  const _handleClose = (): void => {
    onClose?.()
  }

  const _handleThursdayDeliveryBackClick = (): void => {
    setState((prevState) => ({ ...prevState, displayThursdayDelivery: false }))
  }

  const _handleThursdayDeliveryBoxClick = (): void => {

    setState((prevState) => update(prevState, {
      updating: { $set: true },
    }))

    form.submit()

    setState((prevState) => ({ ...prevState, displayThursdayDelivery: false }))
  }

  const _handleSaveChange = (): void => {
    if (state.thursdayDeliverySelected) {
      setState((prevState) => ({ ...prevState, displayThursdayDelivery: true }))
    } else {
      form.submit()
    }
  }

  const _handleSubmit = async (data: EditSubscriptionModalFormData): Promise<void> => {
    setState((prevState) => update(prevState, {
      updating: { $set: true },
    }))

    try {
      await updateSubscription({
        variables: {
          id: userCartData?.currentUser?.activeMenu?.subscription?.id,
          input: { ...data, updateMenu: true },
        },
        refetchQueries: [{
          query: UserCartDocument,
        }, {
          query: UserDetailsDocument,
        }, {
          query: ActiveCampaignWinesDocument,
        }],
        awaitRefetchQueries: true,
      })
      addToast('Subscription plan updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
      sessionStorage.setItem('SlugSessionString', null)
      _handleClose()
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, {
      updating: { $set: false },
    }))
  }

  const _handleFieldsChange = (changedFields: FieldData[]) => {

    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'deliveryOption') {
          setState((prevState) => ({ ...prevState, thursdayDeliverySelected: field.value === 'THURSDAY' }))
        }
      })
    })
  }

  const loading = userDetailsLoading || userCartLoading || mealKitCategoriesLoading || deliveryDayEnumLoading

  const defaultStyles: unknown = {
    position: state.displayThursdayDelivery ? 'absolute' : 'inherit',
    top: state.displayThursdayDelivery ? '-150%' : 'inherit',
    opacity: state.displayThursdayDelivery ? 0 : 1,
  }

  const thursdayDeliveryStyles: unknown = {
    position: state.displayThursdayDelivery ? 'inherit' : 'absolute',
    top: state.displayThursdayDelivery ? 'inherit' : '-150%',
    opacity: state.displayThursdayDelivery ? 1 : 0,
  }

  const thursdayNote = 'TAKE NOTE: You’ve selected Thursday delivery for your order. When your order is billed this Wednesday, it will be delivered the following Thursday. You can change your preferred delivery day up until Wednesday at 9am.'

  return (
    <Modal
      title='Edit your plan'
      open={open}
      fullscreen={false}
      onClose={_handleClose}>
      <Container>
        <Form
          form={form}
          onFinish={_handleSubmit}
          onFieldsChange={_handleFieldsChange}
          disabled={state.updating || loading}>
          <Choose>
            <When condition={state.updating || loading}>
              <SectionLoading />
            </When>
            <Otherwise>
              <TransitionDiv style={thursdayDeliveryStyles}>
                <ThursdayDelivery
                  onDeliverBoxClick={_handleThursdayDeliveryBoxClick}
                  onBackClick={_handleThursdayDeliveryBackClick}
                />
              </TransitionDiv>
              <TransitionDiv style={defaultStyles}>
                <ModalContainer>
                  <SelectInput
                    name='category'
                    label='Meal Kit Category'
                    placeholder={'Choose a category'}
                    options={state.categoryOptions || []}
                    rules={[{ required: true, message: 'Please select a category' }]}
                    className='dropdown' />
                  <Spacer universal='16px' />
                  <SelectInput
                    name='numberOfPortions'
                    label='People you are cooking for'
                    placeholder={'Choose number of people to cook for'}
                    apiEnum='NumberOfPortionsEnum'
                    rules={[{ required: true, message: 'Please select number of people to cook for' }]}
                    className='dropdown' />
                  <Spacer universal='16px' />
                  <SelectInput
                    name='numberOfMeals'
                    label='Number of dishes per week'
                    placeholder={'Choose number of dishes per week'}
                    options={state.numberOfDefaultMealOptions}
                    rules={[{ required: true, message: 'Please select number of dishes per week' }]}
                    className='dropdown' />
                  <Spacer universal='16px' />
                  <SelectInput
                    name='deliveryOption'
                    label='Preferred Delivery Day'
                    placeholder={'Choose a delivery day'}
                    options={state.deliveryOptions || []}
                    rules={[{ required: true, message: 'Please select a delivery day' }]}
                    className='dropdown' />
                  <Choose>
                    <When condition={state.thursdayDeliverySelected}>
                      <Spacer universal='16px' />
                      <Paragraph variant='p2' color={theme.colors.greys.boulder}>
                        {thursdayNote}
                      </Paragraph>
                      <Spacer universal='16px' />
                    </When>
                    <Otherwise>
                      <Spacer universal='24px' />
                    </Otherwise>
                  </Choose>
                  <Spacer universal='24px' />
                  <ActionContainer $stickyBottom>
                    <Button
                      color='black'
                      fullWidth
                      title='SAVE CHANGES'
                      loading={state.updating}
                      disabled={state.updating || loading}
                      onClick={_handleSaveChange} />
                    <Spacer universal='16px' />
                    <Link
                      variant='l2'
                      decoration='underline'
                      onClick={_handleClose}> Cancel </Link>
                  </ActionContainer>
                </ModalContainer>
              </TransitionDiv>
            </Otherwise>
          </Choose>
        </Form>
      </Container>
    </Modal>
  )
}
